<template >
  <div id="blog">
    <div id="headerHome" class="col-sm-12 col-md-12 color-bg-1">
      <HeaderHome :name="user.fullname" />
    </div>

    <div class="politica-cont">
      <div class="titulo-pol">AVISO DE <span>PRIVACIDAD INTEGRAL</span></div>
      <p class="texto-pol">
        <b>DEPORTIVO HMO, S.A. DE C.V.</b>, en lo sucesivo <b>Club Cimarrones de Sonora FC</b>
        empresa legalmente constituida de conformidad con las leyes mexicanas
        con domicilio fiscal en Boulevard Antonio Quiroga número 114, interior
        H, Colonia El Llanito C.P. 83174 en la ciudad de Hermosillo, Sonora, es
        responsable de recabar sus datos personales, del uso que se le de a los
        mismos y de su protección.
      </p>

      <p class="texto-pol"><b>Fines de uso de información recabada</b></p>

      <p class="texto-pol">
        Los datos personales que recabamos de usted, los utilizaremos para las
        siguientes finalidades que son necesarias para el servicio que solicita:
      </p>
      <p class="texto-pol lista-pol">
        • Para hacer envíos de las compras realizadas en nuestra Tienda Online
      </p>
      <p class="texto-pol lista-pol">
        •Informar sobre promociones, actividades y/o eventos
      </p>
      <p class="texto-pol lista-pol">
        • Evaluar calidad en el servicio. De manera adicional, utilizaremos su
        información personal para las siguientes finalidades secundarias que no
        son necesarias para el servicio solicitado, pero que nos permiten y
        facilitan brindarle una mejor atención:
      </p>
      <p class="texto-pol lista-pol">• Mercadotecnia y/o publicidad.</p>

      <p class="texto-pol">
        Los datos que podemos recabar para los fines mencionados en el presente
        Aviso de Privacidad Integral son los siguientes:
      </p>

      <p class="texto-pol lista-pol">• Nombre.</p>
      <p class="texto-pol lista-pol">• Correo electrónico.</p>
      <p class="texto-pol lista-pol">• Dirección.</p>
      <p class="texto-pol lista-pol">• Teléfono.</p>

      <p class="texto-pol">
        Sus datos podrán ser proporcionados a terceros única y exclusivamente a
        fabricantes y/o proveedores, con los que <b>Club Cimarrones de Sonora FC</b>
        mantiene una relación lícita y ética de negocios, para efectos de
        Marketing siempre con las limitaciones señaladas en la Ley Federal de
        Protección de Datos Personales en Posesión de Particulares.
      </p>

      <p class="texto-pol">
        Todos sus datos personales son tratados con estricta confidencialidad de
        acuerdo a la legislación aplicable y vigente en el país, por ello le
        informamos que usted tiene en todo momento los derechos para el Acceso,
        Rectificación, Cancelación u Oposición al tratamiento que le damos a sus
        datos personales (Derechos ARCO), los cuales usted o su representante
        legal podrán hacer valer elaborando la “Solicitud Ejercicio de los
        Derechos ARCO” y enviarla escaneada dirigida a nuestra área de
        Protección de Datos al correo electrónico hola@cimarronesfc.com.mx o por
        escrito en cualquiera de nuestras sucursales. Es importante que
        considere los siguientes puntos al momento de enviar o entregar su
        solicitud:
      </p>

      <p class="texto-pol lista-pol">
        • Indicar su nombre, domicilio y correo electrónico para poder
        comunicarle la respuesta a su solicitud.
      </p>
      <p class="texto-pol lista-pol">
        • Indicar los datos personales respecto de los que se busca ejercer
        alguno de los derechos.
      </p>
      <p class="texto-pol lista-pol">
        • Anexar cualquier documento o información que facilite la localización
        de sus datos personales.
      </p>
      <p class="texto-pol lista-pol">
        • Anexar copia del documento que acredite su identidad (credencial de
        elector, cédula profesional o pasaporte vigente).
      </p>

      <p class="texto-pol">
        Asimismo, usted deberá considerar que para ciertos fines, la revocación
        de su consentimiento implica que no podamos seguir prestando el servicio
        que nos solicita y/o la cancelación de su compra a <b>Club Cimarrones de
        Sonora FC.</b>
      </p>

      <p class="texto-pol"><b>Cambios al aviso de privacidad</b></p>

      <p class="texto-pol">
        <b>DEPORTIVO HMO, S.A. DE C.V.</b> se reserva el derecho de modificar en
        cualquier momento y sin previo aviso el presente aviso de privacidad por
        actualizaciones legislativas, jurisprudenciales, políticas internas,
        nuevos requisitos para la venta de productos en nuestra Tienda Online
        y/o cualquier otra causa. En tal caso, las modificaciones estarán
        disponibles en nuestra página de internet www.cimarronesfc.mx, por lo
        que le recomendamos verificar periódicamente en caso de que existan
        cambios.
      </p>
    </div>

    <div id="footerSection">
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../components/footer";
import HeaderHome from "../components/HeaderHome";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  data() {
    return {};
  },
  components: {
    HeaderHome,
    Footer,
  },
  async beforeMount() {
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
  },
  computed: {
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
  },
};
</script>

<style scoped>
.politica-cont {
    padding: 10.625vw 21.042vw;
}p.texto-pol {
    font-size: 1.042vw;
    line-height: 179.5%;
    text-align: justify;
    color: #A0A0A0;
    margin: 2.2552vw 0;
}.titulo-pol {
    font-size: 2.344vw;
    line-height: 2.708vw;
    color: #B70D1D;
    font-family: 'Rajdhani';
    margin-bottom: 3vw;
}.titulo-pol span {
    color: #01203a;
    font-family:'Rajdhani';
}p.texto-pol.lista-pol {
    margin: 0;
}

@media (max-width: 768px) {
  .politica-cont {
    padding: 33.625vw 11.56vw 10.625vw;
  }.titulo-pol {
    font-size: 4.831vw;
    line-height: 4.831vw;
    margin-bottom: 12.077vw;
  }p.texto-pol {
    font-size: 2.899vw;
    line-height: 179.5%;
    margin: 4.2552vw 0;
}
}
</style>